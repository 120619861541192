<div>
  @for (ssoButton of ssoButtons; track ssoButton.provider) {
  <button
    type="button"
    class="btn btn-grey-very-light sso-button"
    (click)="loginWith(ssoButton.provider)"
  >
    <img src="{{ ssoButton.icon }}" alt="{{ ssoButton.label }} Logo" />
    <span
      >{{
        (isSignUp ? "APP.AUTH.SIGN_UP_WITH" : "APP.AUTH.LOGIN_WITH") | translate
      }}
      {{ ssoButton.label }}</span
    >
  </button>
  }
</div>
