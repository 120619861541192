import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '@services/auth/auth.service';
import { AuthGoogleService } from '@services/auth/auth-google.service';
import { AppRoutes } from '../../../../routes';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from '@services/shared/notification.service';
import { User } from '@models/users/user.model';
import moment from 'moment';

@Component({
  selector: 'app-sso-buttons',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [NotificationService],
  templateUrl: './sso-buttons.component.html',
  styleUrl: './sso-buttons.component.scss',
})
export class SsoButtonsComponent implements OnInit, OnDestroy {
  @Input() isSignUp = false;

  ssoButtons = [
    {
      provider: 'google',
      icon: './assets/social/google.svg',
      label: 'Google',
    },
    // {
    //   provider: 'apple',
    //   icon: './assets/social/apple.svg',
    //   label: 'Apple',
    // },
    // {
    //   provider: 'facebook',
    //   icon: './assets/social/facebook.svg',
    //   label: 'Facebook',
    // },
    // {
    //   provider: 'x',
    //   icon: './assets/social/x.svg',
    //   label: 'X',
    // },
    // {
    //   provider: 'tiktok',
    //   icon: './assets/social/tiktok.svg',
    //   label: 'TikTok',
    // },
    // {
    //   provider: 'instagram',
    //   icon: './assets/social/instagram.svg',
    //   label: 'Instagram',
    // },
  ];

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private authGoogleService: AuthGoogleService,
    private authService: AuthService,
    private oAuthService: OAuthService,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    // Subscribe to OAuth events
    this.oAuthService.events
      .pipe(takeUntil(this._onDestroy))
      .subscribe((event) => {
        if (event.type === 'token_received') {
          this.loginOrRegister();
        }
      });

    // Try to parse the token from the URL if it exists (OAuth callback)
    this.oAuthService.tryLogin().then(() => {
      if (this.oAuthService.hasValidAccessToken()) {
        this.loginOrRegister();
      }
    });
  }

  loginWith(provider: string) {
    if (provider === 'google') {
      this.authGoogleService.login();
    }
  }

  loginOrRegister() {
    const idToken = this.authGoogleService.getIdToken();
    if (idToken) {
      this.authService.loginWithSSOIdToken(
        idToken,
        true,
        (user: User) => {
          if (
            user &&
            moment(user.createdAt).isBetween(
              moment().subtract(1, 'minutes'),
              moment(),
            )
          ) {
            this.router.navigate(['/', AppRoutes.Customer.welcome]);
          }
        },
        () => {
          this.notificationService.error(
            this.translateService.instant('APP.AUTH.ERRORS.SSO'),
          );
          this.authGoogleService.logout();
        },
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.AUTH.ERRORS.SSO'),
      );
      this.authGoogleService.logout();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
